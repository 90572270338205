import React from 'react';
import '../App.css';

const StartPage = ({ onStartGuest, onStartChallenge }) => {
  return (
    <div className="start-page">
      <div className="video-container">
        <video autoPlay loop muted>
          <source src="/video/intro_video.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      <div className="start-buttons">
        <button onClick={onStartGuest} className="start-button guest">
          Guest Mode
        </button>
        <button onClick={onStartChallenge} className="start-button challenge">
          Challenge Mode
        </button>
      </div>
    </div>
  );
};

export default StartPage;